import { useEffect, useState, useRef } from "react";

import { DROP_POSITIONS, DropPositionType } from "../DateInput";

const useDetermineDropdownPosition = (
  ddRef: React.MutableRefObject<HTMLDivElement>,
  ddHeight: number,
  defaultPos: DropPositionType,
  scrollContainerSelector: string,
) => {
  const mounted = useRef(true);
  const [dropPos, setDropPos] = useState<string>(defaultPos);

  const determineDropUp = () => {
    if (defaultPos !== DROP_POSITIONS.AUTO) return;
    const node = ddRef.current;

    if (!node) {
      if (mounted.current) {
        setDropPos("top-[calc(100%+8px)] !bottom-[unset]");
      }
      return;
    }

    const windowHeight = window.innerHeight;
    const calendarHeight = ddHeight;
    const nodeBottomPos = node.getBoundingClientRect().bottom;
    const position =
      nodeBottomPos + calendarHeight >= windowHeight
        ? "!top-[unset] bottom-[calc(100%+8px)]"
        : "top-[calc(100%+8px)] !bottom-[unset]";

    if (mounted.current) {
      setDropPos(position);
    }
  };

  useEffect(() => {
    mounted.current = true;
    const scrollContainerElement = scrollContainerSelector
      ? document.querySelector(scrollContainerSelector)
      : null;

    determineDropUp();
    window.addEventListener("resize", determineDropUp);
    window.addEventListener("scroll", determineDropUp);

    if (scrollContainerElement) {
      scrollContainerElement.addEventListener("scroll", determineDropUp);
    }

    return () => {
      mounted.current = false;
      window.removeEventListener("resize", determineDropUp);
      window.removeEventListener("scroll", determineDropUp);

      if (scrollContainerElement) {
        scrollContainerElement.removeEventListener("scroll", determineDropUp);
      }
    };
  }, []);

  return dropPos;
};

export default useDetermineDropdownPosition;
