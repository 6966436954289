import Check from "@shared/svg/check.svg?react";
import { DropdownAvatar } from "@shared/ui/Avatar";
import BodyText, { BODY_TEXT_SIZES } from "@shared/ui/BodyText";

type DropdownItemPropsType = {
  option: any;
  found: boolean;
  showAvatar?: boolean;
  onClick: () => void;
  maxLabelWidth?: string;
};

const DropdownItem = ({
  option,
  found,
  showAvatar = false,
  onClick = () => {},
  maxLabelWidth = "",
}: DropdownItemPropsType) => {
  const Icon = option.iconName;

  return (
    <div
      key={option.value}
      onClick={onClick}
      className="w-full relative flex items-center justify-between py-sm px-md font-manrope text-xs font-medium text-primary transition-all duration-200 ease-in-out hover:bg-accent"
    >
      <div className="flex items-center">
        {showAvatar && (
          <DropdownAvatar
            className="dropdown-item-avatar mr-sm"
            name={option.name || option.label}
          />
        )}

        {option.thumbnail && (
          <img
            src={option.thumbnail}
            className="w-3xl h-2xl rounded object-cover mr-sm"
            alt={option.thumbnail}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = "/images/default/thumbnail.svg";
            }}
          />
        )}

        {option.iconName && (
          <div className="flex items-center justify-center mr-sm">
            <Icon
              width={option.size || 14}
              height={option.size || 14}
              className={option.className || ""}
              style={option.iconStyle || {}}
            />
          </div>
        )}
        <div className="flex flex-col">
          <div className="flex items-center">
            <BodyText
              size={BODY_TEXT_SIZES.X_SMALL}
              color="text-primary"
              className={`truncate ${
                maxLabelWidth || (option.description ? "max-w-52" : "max-w-80")
              }`}
            >
              {option.label}
            </BodyText>
            {option.description && (
              <BodyText
                size={BODY_TEXT_SIZES.X_SMALL}
                color="text-secondary"
                className="max-w-36 truncate"
              >
                <span className="text-primary">&nbsp;•&nbsp;</span>
                {option.description}
              </BodyText>
            )}
          </div>

          {option.access && (
            <p className="text-2xs text-tertiary m-0 mt-2xs">{option.access}</p>
          )}
        </div>
      </div>

      {found && (
        <span className="flex items-center justify-center ml-sm">
          <Check width={16} height={16} className="text-brand" />
        </span>
      )}
    </div>
  );
};

export default DropdownItem;
