import * as React from "react";
const SvgCalendarIcon = (props) => /* @__PURE__ */ React.createElement("svg", {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 20 20",
  ...props
}, /* @__PURE__ */ React.createElement("path", {
  stroke: "currentColor",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: 1.2,
  d: "M6.248 2.497v2.5m7.504-2.5v2.5M2.497 7.916h15.006"
}), /* @__PURE__ */ React.createElement("rect", {
  width: 15.006,
  height: 13.756,
  x: 2.497,
  y: 3.747,
  stroke: "currentColor",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: 1.2,
  rx: 3
}));
export default SvgCalendarIcon;
