import BodyText, { BODY_TEXT_SIZES } from "@shared/ui/BodyText";
import { ErrorMessage, InputLabel } from "@shared/ui/Inputs";

export const RADIO_BUTTON_SIZES = {
  NORMAL: "normal",
  SMALL: "small",
} as const;

type RadioButtonSizeType =
  (typeof RADIO_BUTTON_SIZES)[keyof typeof RADIO_BUTTON_SIZES];

const SIZE_STYLES: Record<RadioButtonSizeType, string> = {
  [RADIO_BUTTON_SIZES.NORMAL]: "w-lg h-lg",
  [RADIO_BUTTON_SIZES.SMALL]: "h-md w-md",
};

type OptionType = {
  name?: string;
  value: string;
  heading?: string;
};

type RadioButtonInputPropsType = {
  size?: RadioButtonSizeType;
  label?: string;
  keyId: string;
  uppercaseLabel?: boolean;
  errorMessage?: string;
  vertical?: boolean;
  options: OptionType[];
  value?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  disabled?: boolean;
  buttonOnly?: boolean;
  isChecked?: boolean;
};

const RadioButtonInput = ({
  size = RADIO_BUTTON_SIZES.NORMAL,
  label = "",
  keyId,
  uppercaseLabel = false,
  errorMessage = "",
  vertical = false,
  options,
  value = "",
  onChange = () => {},
  disabled = false,
  buttonOnly = false,
  isChecked = false,
}: RadioButtonInputPropsType) => {
  const radioInputClasses: string = `m-0 appearance-none ${SIZE_STYLES[size]} ${
    disabled ? "cursor-default" : "cursor-pointer"
  } rounded-full bg-primary border-solid border border-secondary checked:border-accent checked:border-4`;

  if (buttonOnly) {
    return (
      <input
        type="radio"
        id={keyId}
        name={keyId}
        checked={isChecked}
        disabled={disabled}
        className={radioInputClasses}
      />
    );
  }

  return (
    <div className="flex flex-col gap-sm">
      {label && (
        <InputLabel
          label={label}
          keyId={keyId}
          uppercaseLabel={uppercaseLabel}
        />
      )}

      <div
        className={`flex ${vertical ? "flex-col gap-lg" : "gap-2xl flex-wrap"}`}
      >
        {options.map((option) => (
          <div
            key={option.value}
            className={`flex ${
              option.heading ? "items-start" : "items-center"
            } gap-sm`}
          >
            <input
              type="radio"
              id={`${keyId}-${option.value}`}
              name={keyId}
              value={option.value}
              onChange={onChange}
              checked={value === option.value}
              disabled={disabled}
              className={`${
                option.heading ? "mt-3xs" : ""
              } ${radioInputClasses}`}
            />
            <div className="flex flex-col gap-2xs items-start w-fit">
              {option.heading && (
                <label
                  htmlFor={`${keyId}-${option.value}`}
                  className={`font-manrope font-bold text-sm ${
                    disabled
                      ? "cursor-default text-disabled"
                      : "cursor-pointer text-primary"
                  } ${uppercaseLabel ? "uppercase" : ""}`}
                >
                  {option.heading}
                </label>
              )}
              {option.heading ? (
                <BodyText
                  color={`${disabled ? "text-disabled" : "text-secondary"}`}
                  size={BODY_TEXT_SIZES.X_SMALL}
                >
                  {option.name}
                </BodyText>
              ) : (
                <label
                  htmlFor={`${keyId}-${option.value}`}
                  className={`font-manrope font-medium text-xs ${
                    disabled
                      ? "cursor-default text-disabled"
                      : "cursor-pointer text-secondary"
                  } ${uppercaseLabel ? "uppercase" : ""}`}
                >
                  {option.name}
                </label>
              )}
            </div>
          </div>
        ))}
      </div>

      {errorMessage && <ErrorMessage message={errorMessage} />}
    </div>
  );
};

export default RadioButtonInput;
