import BodyText, { BODY_TEXT_SIZES } from "@shared/ui/BodyText";
import Label, { LABEL_SIZES } from "@shared/ui/Label";
import { Trash } from "phosphor-react";
import React from "react";
import { useIntl } from "react-intl";

import { getPartThumbnail } from "#/src/utils";

const SinglePart = ({
  part,
  onDeleteClick,
  machine,
  disableDelete = false,
  extraClass,
  ...props
}) => {
  const { messages } = useIntl();

  return (
    <>
      <div
        className={`md:!hidden lg:!flex xl:!hidden single-part ${extraClass}`}
        {...props}
      >
        <div className="single-part__image">
          <img src={getPartThumbnail(part, true)} />
        </div>
        <div className="single-part__info">
          <h4 className="single-part__info-title">{part.name}</h4>
          <div className="single-part__info-meta">
            <span>{messages?.parts?.labels.articleNumber}:</span>{" "}
            {part.articleNumber} &nbsp; &#8226;{" "}
            <span>{messages?.tickets?.quantity}:</span> {part.quantity}
          </div>
        </div>
        {!disableDelete && (
          <span className="single-part__action" onClick={onDeleteClick}>
            <Trash size={16} />
          </span>
        )}
      </div>

      <div
        className="relative cursor-pointer items-center p-0 mt-md hidden md:flex lg:hidden xl:flex"
        onClick={props.onClick}
      >
        <div className="w-8/12 flex">
          <div className="w-5xl h-4xl rounded-lg overflow-hidden mr-md shrink-0">
            <img
              src={getPartThumbnail(part, true)}
              alt={part.name}
              className="w-5xl h-4xl object-cover object-center"
            />
          </div>
          <div className="grow flex flex-col overflow-hidden justify-center">
            <Label size={LABEL_SIZES.SMALL} className="truncate">
              {part.name}
            </Label>
          </div>
        </div>
        <div className="w-2/12">
          <div className="grow">
            <BodyText size={BODY_TEXT_SIZES.X_SMALL} className="truncate">
              {part.articleNumber}
            </BodyText>
          </div>
        </div>
        <div className="w-2/12">
          <div className="grow">
            <BodyText size={BODY_TEXT_SIZES.X_SMALL}>{part.quantity}</BodyText>
          </div>
        </div>
      </div>
    </>
  );
};

export default SinglePart;
