import { ITEMS_BEFORE_PAGE } from "@shared/constants/index";
import { useEffect, Fragment } from "react";
import { useIntl } from "react-intl";
import { Waypoint } from "react-waypoint";

import DropdownItem from "../DropdownItem";

export type WaypointEventType = {
  currentPosition: string;
  event?: Event;
  previousPosition: string;
  viewportBottom: number;
  viewportTop: number;
  waypointTop: number;
};

type SingleSelectAsyncItemProps = {
  isLoading?: boolean;
  onScrollToBottom?: (event: WaypointEventType) => void;
  onSearch?: (term: string) => void;
  props: any;
  state: any;
  methods: any;
  showAvatar?: boolean;
};

const SingleSelectAsyncItem = ({
  isLoading = false,
  onScrollToBottom,
  onSearch = () => {},
  props,
  state,
  methods,
  showAvatar = false,
}: SingleSelectAsyncItemProps) => {
  const { messages } = useIntl();

  const items = props.options;

  useEffect(() => onSearch(state.search), [state.search]);

  return (
    <>
      {items?.map((option, index) => {
        const foundIndex = state.values.findIndex(
          (v) => v.value === option.value,
        );
        const found = foundIndex >= 0;

        return (
          <Fragment key={option.value}>
            <DropdownItem
              option={option}
              onClick={() => methods.addItem(option)}
              found={found}
              showAvatar={showAvatar}
            />
            {index === items.length - ITEMS_BEFORE_PAGE && (
              <Waypoint onEnter={onScrollToBottom} />
            )}
          </Fragment>
        );
      })}

      {isLoading ? (
        <span className="px-lg py-sm font-manrope text-xs font-medium text-primary">
          {messages?.common?.loading}
        </span>
      ) : null}

      {!items?.length && !isLoading && (
        <span className="px-lg py-sm font-manrope text-xs font-medium text-primary">
          {messages?.common?.noOption}
        </span>
      )}
    </>
  );
};

export default SingleSelectAsyncItem;
