import Button, { BUTTON_VARIANTS, ButtonVariantType } from "@shared/ui/Button";
import Headline, { HEADLINE_SIZES } from "@shared/ui/Headline";
import { X } from "phosphor-react";
import React from "react";
import ReactDOM from "react-dom";

type DrawerPropsType = {
  isOpen: boolean;
  overlay?: boolean;
  headerClassName?: string;
  title?: string;
  secondaryHeaderButton?: React.ReactNode;
  hideCloseButton?: boolean;
  onClose: () => void;
  contentClassName?: string;
  hideFooter?: boolean;
  footerClassName?: string;
  cancelButtonVariant?: ButtonVariantType;
  cancelButtonText?: string;
  onCancel?: () => void;
  cancelButtonDisabled?: boolean;
  submitButtonText?: string;
  onSubmit?: () => void;
  submitButtonDisabled?: boolean;
  children: React.ReactNode;
  zIndexClass?: string;
  headerComponent?: React.ReactNode;
  width?: string;
};

const Drawer = ({
  isOpen,
  overlay = true,
  headerClassName = "",
  title = "",
  secondaryHeaderButton = null,
  hideCloseButton = false,
  onClose,
  contentClassName = "",
  hideFooter = false,
  footerClassName = "",
  cancelButtonVariant = BUTTON_VARIANTS.OUTLINE,
  cancelButtonText = "",
  onCancel = () => {},
  cancelButtonDisabled = false,
  submitButtonText = "",
  onSubmit = () => {},
  submitButtonDisabled = true,
  children,
  zIndexClass = "z-10",
  width = "w-[490px]",
  headerComponent = null,
}: DrawerPropsType) => {
  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <div
      className={`fixed top-0 left-0  w-full h-screen ${
        overlay ? "bg-secondary/60" : ""
      } ${zIndexClass}`}
    >
      <div
        className={`fixed top-0 right-0 max-md:bottom-0 flex flex-col items-center justify-between max-md:w-full h-screen bg-primary ${width}`}
      >
        {headerComponent ? (
          headerComponent
        ) : (
          <div
            className={`flex items-center justify-between w-full px-2xl py-xl border-solid border-b border-t-0 border-x-0 border-primary ${headerClassName}`}
          >
            <Headline
              size={HEADLINE_SIZES.X_SMALL}
              className="break-all truncate"
            >
              {title}
            </Headline>

            <div className="flex">
              {secondaryHeaderButton}
              {hideCloseButton ? null : (
                <button
                  onClick={onClose}
                  className="w-3xl h-3xl ml-xl flex items-center justify-center cursor-pointer rounded-lg transition-all duration-200 ease-in-out bg-primary border border-solid border-primary hover:border-brand"
                >
                  <X size={16} className="text-primary" />
                </button>
              )}
            </div>
          </div>
        )}

        <div
          className={`grow-[2] w-full px-2xl py-xl py-0 overflow-x-hidden overflow-y-auto ${contentClassName}`}
        >
          {children}
        </div>

        {!hideFooter && (
          <div
            className={`w-full flex items-center justify-between gap-sm px-2xl py-lg border-solid border-t border-b-0 border-x-0 border-primary ${footerClassName}`}
          >
            {cancelButtonText && (
              <Button
                variant={cancelButtonVariant}
                text={cancelButtonText}
                className="flex-1"
                onClick={onCancel}
                disabled={cancelButtonDisabled}
              />
            )}

            {submitButtonText && (
              <Button
                text={submitButtonText}
                className="flex-1"
                onClick={onSubmit}
                disabled={submitButtonDisabled}
              />
            )}
          </div>
        )}
      </div>
    </div>,
    document.body,
  );
};

export default Drawer;
