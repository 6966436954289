import * as React from "react";
const SvgCaretRight = (props) => /* @__PURE__ */ React.createElement("svg", {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 20 20",
  ...props
}, /* @__PURE__ */ React.createElement("path", {
  stroke: "currentColor",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: 1.2,
  d: "M7.5 3.75 13.75 10 7.5 16.25"
}));
export default SvgCaretRight;
