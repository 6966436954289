import * as React from "react";
const SvgCaretLeft = (props) => /* @__PURE__ */ React.createElement("svg", {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 20 20",
  ...props
}, /* @__PURE__ */ React.createElement("g", {
  clipPath: "url(#caret-left)"
}, /* @__PURE__ */ React.createElement("path", {
  stroke: "currentColor",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: 1.2,
  d: "M12.5 16.25 6.25 10l6.25-6.25"
})), /* @__PURE__ */ React.createElement("defs", null, /* @__PURE__ */ React.createElement("clipPath", {
  id: "caret-left"
}, /* @__PURE__ */ React.createElement("path", {
  fill: "#fff",
  d: "M0 0h20v20H0z"
}))));
export default SvgCaretLeft;
