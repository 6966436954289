import Tooltip from "@shared/ui/Tooltip";
import React, { useRef, useState, useMemo } from "react";
import useOnClickOutside from "use-onclickoutside";

import Avatar from "~/components/_avatar";
import { isTextEllipsized } from "~/utils";

const UserOptions = ({ user, logout }) => {
  const [isOpen, setIsOpen] = useState(false);

  const ref = useRef(null);
  const userNameRef = useRef(null);
  const userName = useMemo(
    () => (user?.name ? user?.name : user?.email),
    [user?.name, user?.email],
  );

  const toggle = () => {
    if (isOpen) {
      setIsOpen(false);
    } else {
      setIsOpen(true);
    }
  };

  const close = () => {
    setIsOpen(false);
  };

  useOnClickOutside(ref, close);

  return (
    <>
      <div className="header-user" ref={ref}>
        <div className={`c-dropdown ${isOpen ? "c-dropdown--shown" : ""}`}>
          <button
            className="c-button header-userDropdownButton"
            onClick={toggle}
          >
            <Avatar name={user?.name} className="header-userAvatar" />
            {typeof user !== "undefined" && user !== null && (
              <div
                ref={userNameRef}
                data-for="header-username-tooltip"
                data-tip={isTextEllipsized(userNameRef.current) ? userName : ""}
                data-event="mouseenter"
                data-event-off="mouseleave"
                className="multi-truncate line-clamp--2"
              >
                {userName}
              </div>
            )}
          </button>
        </div>
      </div>
      <Tooltip id="header-username-tooltip" />
    </>
  );
};

export default UserOptions;
