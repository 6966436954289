import BodyText, { BODY_TEXT_SIZES } from "@shared/ui/BodyText";
import Button, { BUTTON_VARIANTS } from "@shared/ui/Button";
import Headline, { HEADLINE_SIZES } from "@shared/ui/Headline";
import { Counter } from "@shared/ui/Inputs";
import Label, { LABEL_SIZES } from "@shared/ui/Label";
import { PartListItem } from "@shared/ui/ListItemCards";
import AppModal from "@shared/ui/Modal";
import { Trash, Eye, CaretLeft } from "phosphor-react";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";

import EmptySpace from "~/components/_emptySpace";
import { getPartThumbnail } from "~/utils";

export const PartRow = ({
  part,
  handlers,
  addPartToCard,
  counter = false,
  machine,
  hideDescription = false,
}) => {
  const { messages } = useIntl();
  const [countValue, setCountValue] = useState(0);
  const [showDetails, setShowDetails] = useState(false);

  const incrementCount = () => {
    addPartToCard(part);
    setCountValue(countValue + 1);
  };

  const decrementCount = () => {
    if (countValue > 0) {
      addPartToCard(part, false);
      setCountValue(countValue - 1);
    }
  };

  const removePart = () => {
    addPartToCard(part, false, true);
    setCountValue(0);
  };

  const showDetailsClick = () => {
    setShowDetails(true);
  };

  const hideDetailsClick = () => {
    setShowDetails(false);
  };

  return (
    <div
      key={part._id}
      className="parts-table__row u-flex u-items-center cursor-pointer"
      onClick={(e) => {
        e.stopPropagation();
        if (!handlers) return;
        handlers.handleNameClick(part._id);
      }}
    >
      <div className="parts-table__col u-width-4">
        <div className="u-flex u-items-center parts-table__col--name">
          <div className="parts-table-item__thumb-container">
            <img
              className="parts-table-item__thumb"
              src={getPartThumbnail(part)}
            />
          </div>
          <div className="parts-table-item__info">
            <div className="parts-table-item__name sm-hide">
              <Label size={LABEL_SIZES.SMALL} className="truncate">
                {part.name}
              </Label>
            </div>
            <div className="parts-table-item__article md-hide">
              {part.articleNumber}
            </div>
            <div className="parts-table-item__name md-hide">
              <Label size={LABEL_SIZES.SMALL}>{part.name}</Label>
            </div>
          </div>
          <div className="parts-info-mobile-device u-items-center u-justify-between">
            <div className="parts-table-item__article">
              {part.articleNumber}
            </div>
            <button onClick={showDetailsClick}>
              <Eye size={12} weight="bold" />
              <span>{messages?.parts?.partMobileDetails}</span>
            </button>
          </div>
        </div>
      </div>
      {!hideDescription && (
        <div className="parts-table__col u-width-4 sm-p-0">
          <div className="desc sm-hide">
            <BodyText size={BODY_TEXT_SIZES.X_SMALL} className="truncate">
              {part.description || "-"}
            </BodyText>
          </div>
        </div>
      )}
      {!counter && (
        <div
          className={"parts-table__col u-width-2 sm-hide text-elipsis"}
        ></div>
      )}
      <div className={"parts-table__col u-width-2 sm-hide text-elipsis"}>
        <BodyText size={BODY_TEXT_SIZES.X_SMALL} className="truncate">
          {part.articleNumber}
        </BodyText>
      </div>
      {counter && (
        <div className="parts-table__col u-width-2">
          <div className="part-counter-wrap">
            <Label size={LABEL_SIZES.SMALL} className="max-md:w-full md:hidden">
              {part.name}
            </Label>
            <span>{messages?.parts?.addPartQuantity}</span>
            <div className="flex w-full">
              <Counter
                count={countValue}
                setCount={setCountValue}
                incrementCount={incrementCount}
                decrementCount={decrementCount}
              />
            </div>
            {countValue > 0 && (
              <span className="remove-parts-action" onClick={removePart}>
                <Trash size={16} />
              </span>
            )}
          </div>
        </div>
      )}
      <AppModal
        disableUpdate={!countValue?.length}
        isOpen={showDetails}
        header={
          <div className="flex items-center py-xl px-2xl border-solid border-b border-t-0 border-x-0 border-primary">
            <Button
              leadingIcon={<CaretLeft size={16} weight="bold" />}
              variant={BUTTON_VARIANTS.OUTLINE}
              onClick={hideDetailsClick}
              className="w-4xl mr-md"
            />
            <Headline size={HEADLINE_SIZES.X_SMALL}>
              {messages?.parts?.partDetails}
            </Headline>
          </div>
        }
        footer={
          <div className="flex items-center justify-end py-lg px-2xl border-solid border-t border-b-0 border-x-0 border-primary gap-xl">
            <Counter
              count={countValue}
              setCount={setCountValue}
              incrementCount={incrementCount}
              decrementCount={decrementCount}
            />
            <Button
              disabled={countValue < 1}
              fullWidth
              text={messages?.parts?.addPart}
              onClick={hideDetailsClick}
            />
          </div>
        }
        handleSubmit={() => {}}
        handleClose={() => {
          hideDetailsClick();
        }}
        className="spare-part-details-modal"
        contentClassName="flex-1"
        content={
          <>
            <div className="part-detals-content">
              <div className="part-thumbnail">
                <img
                  className="parts-table-item__thumb"
                  src={getPartThumbnail(part)}
                />
              </div>
              <BodyText size={BODY_TEXT_SIZES.X_SMALL} color="text-secondary">
                {messages?.parts?.labels?.name}
              </BodyText>
              <EmptySpace height="6px" />
              <BodyText size={BODY_TEXT_SIZES.X_SMALL}>{part.name}</BodyText>
              <EmptySpace height="12px" />
              <div className="part-desc">
                <EmptySpace height="12px" />
                <BodyText size={BODY_TEXT_SIZES.X_SMALL} color="text-secondary">
                  {messages?.parts?.labels?.description}
                </BodyText>
                <EmptySpace height="6px" />
                <BodyText size={BODY_TEXT_SIZES.X_SMALL}>
                  {part.description || "-"}
                </BodyText>
                <EmptySpace height="12px" />
              </div>
            </div>
          </>
        }
        updateButtonText={messages?.parts?.assignParts}
      />
    </div>
  );
};

export const RequestedPartAddedItem = ({
  part,
  handlers,
  addPartToCard,
  machine,
}) => {
  const { messages } = useIntl();
  const [countValue, setCountValue] = useState(0);
  const [showDetails, setShowDetails] = useState(false);

  const incrementCount = () => {
    addPartToCard(part);
    setCountValue(countValue + 1);
  };

  const decrementCount = () => {
    if (countValue > 0) {
      addPartToCard(part, false);
      setCountValue(countValue - 1);
    }
  };

  const removePart = () => {
    addPartToCard(part, false, true);
    setCountValue(0);
  };

  const showDetailsClick = () => {
    setShowDetails(true);
  };

  const hideDetailsClick = () => {
    setShowDetails(false);
  };

  useEffect(() => {
    setCountValue(part?.quantity);
  }, [part]);

  return (
    <div
      className="parts-table__row u-flex u-items-center cursor-pointer"
      onClick={(e) => {
        e.stopPropagation();
        if (!handlers) return;
        handlers.handleNameClick(part._id);
      }}
    >
      <div className="parts-table__col u-width-8">
        <div className="u-flex u-items-center parts-table__col--name">
          <div className="parts-table-item__thumb-container">
            <img
              className="parts-table-item__thumb"
              src={getPartThumbnail(part)}
            />
          </div>
          <div className="parts-table-item__info sm-desktop-element">
            <div className="parts-table-item__name">
              <Label size={LABEL_SIZES.SMALL}>{part?.name}</Label>
            </div>
          </div>
          <div className="parts-info-mobile-device u-items-center u-justify-between">
            <div className="parts-table-item__article">
              {part.articleNumber}
            </div>
            <button onClick={showDetailsClick}>
              <Eye size={12} weight="bold" />
              <span>{messages?.parts?.partMobileDetails}</span>
            </button>
          </div>
        </div>
      </div>
      <div className="parts-table__col u-width-4">
        <div className="part-counter-wrap">
          <Label size={LABEL_SIZES.SMALL} className="max-md:w-full md:hidden">
            {part.name}
          </Label>
          <div className="flex w-full">
            <Counter
              count={countValue}
              setCount={setCountValue}
              incrementCount={incrementCount}
              decrementCount={decrementCount}
            />
          </div>
          {countValue > 0 && (
            <span className="remove-parts-action" onClick={removePart}>
              <Trash size={16} />
            </span>
          )}
        </div>
      </div>
      <AppModal
        disableUpdate={!countValue?.length}
        isOpen={!showDetails}
        header={
          <div className="part-details-header u-flex u-items-center">
            <button onClick={hideDetailsClick}>
              <CaretLeft size={16} weight="bold" />
            </button>
            <Headline size={HEADLINE_SIZES.X_SMALL}>
              {messages?.parts?.partDetails}
            </Headline>
          </div>
        }
        footer={
          <div className="part-details-footer u-flex u-items-center u-width-100">
            <Counter
              count={countValue}
              setCount={setCountValue}
              incrementCount={incrementCount}
              decrementCount={decrementCount}
            />
            <button
              disabled={countValue < 1}
              className="add-part-button"
              onClick={hideDetailsClick}
            >
              {messages?.parts?.addPart}
            </button>
          </div>
        }
        handleSubmit={() => {}}
        handleClose={() => {
          hideDetailsClick();
        }}
        className="spare-part-details-modal"
        content={
          <>
            <div className="part-detals-content">
              <div className="part-thumbnail">
                <img
                  className="parts-table-item__thumb"
                  src={getPartThumbnail(part)}
                />
              </div>
              <BodyText size={BODY_TEXT_SIZES.X_SMALL} color="text-secondary">
                {messages?.parts?.labels?.name}
              </BodyText>
              <EmptySpace height="6px" />
              <BodyText size={BODY_TEXT_SIZES.X_SMALL}>{part.name}</BodyText>
              <EmptySpace height="12px" />
              <div className="part-desc">
                <EmptySpace height="12px" />
                <BodyText size={BODY_TEXT_SIZES.X_SMALL} color="text-secondary">
                  {messages?.parts?.labels?.description}
                </BodyText>
                <EmptySpace height="6px" />
                <BodyText size={BODY_TEXT_SIZES.X_SMALL}>
                  {part.description || "-"}
                </BodyText>
                <EmptySpace height="12px" />
              </div>
            </div>
          </>
        }
        updateButtonText={messages?.parts?.assignParts}
      />
    </div>
  );
};

export const CreateWorkOrderPartRow = ({
  part,
  handlers,
  addPartToCard,
  onShowDetails,
  detailPart,
  defaultCount = 0,
}) => {
  const { messages } = useIntl();
  const [countValue, setCountValue] = useState(defaultCount);

  const incrementCount = () => {
    addPartToCard(part);
    setCountValue(countValue + 1);
  };

  const decrementCount = () => {
    if (countValue > 0) {
      addPartToCard(part, false);
      setCountValue(countValue - 1);
    }
  };

  const removePart = () => {
    addPartToCard(part, false, true);
    setCountValue(0);
  };

  const showDetailsClick = () => {
    onShowDetails({ ...part, countValue });
  };

  useEffect(() => {
    if (detailPart && detailPart?._id === part._id) {
      const val = detailPart.countValue;
      setCountValue(val ?? 0);
      addPartToCard(part, val > 0, val <= 0, true, val);
    }
  }, [detailPart]);

  return (
    <div
      key={part._id}
      onClick={(e) => {
        e.stopPropagation();
        if (!handlers) return;
        handlers.handleNameClick(part._id);
      }}
    >
      <PartListItem
        articleNumber={part.articleNumber}
        name={part.name}
        thumbnail={getPartThumbnail(part)}
        onButtonClick={showDetailsClick}
        showDeleteButton={countValue > 0}
        onDeleteButtonClick={removePart}
        counterProps={{
          count: countValue,
          setCount: (val) => {
            addPartToCard(part, val > 0, val <= 0, true, val);
            setCountValue(val);
          },
          incrementCount,
          decrementCount,
          isReadOnlyInput: false,
        }}
      />
    </div>
  );
};
