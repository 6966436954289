import DeleteIcon from "@shared/svg/delete-icon.svg?react";
import BodyText, { BODY_TEXT_SIZES } from "@shared/ui/BodyText";
import Button, { BUTTON_VARIANTS } from "@shared/ui/Button";
import Headline, { HEADLINE_SIZES } from "@shared/ui/Headline";
import Label, { LABEL_SIZES } from "@shared/ui/Label";
import { MinusCircle } from "phosphor-react";
import React from "react";
import { useIntl } from "react-intl";

type ProductionLineListItemPropsType = {
  productionLine: any;
  hideFacility?: boolean;
  className?: string;
  teamsComponent?: React.ReactNode;
  showDeleteButton?: boolean;
  showUnassign?: boolean;
  tooltipText?: string;
  onButtonClick?: React.MouseEventHandler<HTMLButtonElement>;
};

const ProductionLineListItem = ({
  productionLine,
  hideFacility = false,
  className = "",
  teamsComponent = null,
  showDeleteButton = true,
  showUnassign = false,
  tooltipText = "",
  onButtonClick = () => {},
}: ProductionLineListItemPropsType) => {
  const { messages } = useIntl();

  return (
    <div
      className={`md:h-9xl px-lg max-md:py-lg mb-lg border border-solid border-primary rounded-lg flex items-center cursor-pointer bg-white hover:bg-accent ${className}`}
    >
      <div
        className={`truncate flex flex-col w-full ${
          teamsComponent ? "md:w-1/4" : "md:w-7/12"
        }`}
      >
        <Headline size={HEADLINE_SIZES.X_SMALL} className="truncate">
          {productionLine.name}
        </Headline>
        {!hideFacility && (
          <Label
            size={LABEL_SIZES.MEDIUM}
            color="text-brand"
            className={`truncate ${productionLine.customer ? "mt-2xs" : ""}`}
          >
            {productionLine.customer?.name}
          </Label>
        )}
      </div>

      <div className="flex items-center md:justify-end md:pr-7 md:w-2/12 w-full">
        <BodyText size={BODY_TEXT_SIZES.X_SMALL} color="text-secondary">
          {productionLine.numberOfMachines ?? 0}
        </BodyText>
        <BodyText
          size={BODY_TEXT_SIZES.X_SMALL}
          className="truncate ml-2xs"
          title={messages?.common.machines}
        >
          {messages?.common.machines}
        </BodyText>
      </div>

      <div className="flex items-center truncate md:pl-7 md:w-2/12 w-full">
        <BodyText size={BODY_TEXT_SIZES.X_SMALL} color="text-secondary">
          {messages?.common.id + ": "}
        </BodyText>
        <BodyText
          size={BODY_TEXT_SIZES.X_SMALL}
          className="truncate ml-2xs"
          title={productionLine.lineId}
        >
          {productionLine.lineId}
        </BodyText>
      </div>

      {teamsComponent && <div className="w-4/12">{teamsComponent}</div>}

      {showDeleteButton && (
        <div className="flex w-1/12 p-0 justify-end">
          <Button
            variant={BUTTON_VARIANTS.LINK}
            leadingIcon={
              showUnassign ? (
                <MinusCircle className="text-secondary" size={20} />
              ) : (
                <DeleteIcon className="text-secondary" width={20} height={20} />
              )
            }
            tooltipId="delete-production-line"
            tooltipText={tooltipText}
            onClick={onButtonClick}
          />
        </div>
      )}
    </div>
  );
};

export default ProductionLineListItem;
