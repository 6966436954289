import * as React from "react";
const SvgCheck = (props) => /* @__PURE__ */ React.createElement("svg", {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 16 16",
  ...props
}, /* @__PURE__ */ React.createElement("g", {
  clipPath: "url(#check)"
}, /* @__PURE__ */ React.createElement("path", {
  stroke: "currentColor",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: 1.2,
  d: "m13.5 4.5-7 7L3 8"
})), /* @__PURE__ */ React.createElement("defs", null, /* @__PURE__ */ React.createElement("clipPath", {
  id: "check"
}, /* @__PURE__ */ React.createElement("path", {
  fill: "#fff",
  d: "M0 0h16v16H0z"
}))));
export default SvgCheck;
