import CalendarIcon from "@shared/svg/calendar-icon.svg?react";
import CalendarComponent, { CalendarValueType } from "@shared/ui/Calendar";
import { ErrorMessage, InputLabel } from "@shared/ui/Inputs";
import moment from "moment-timezone";
import { useEffect, useRef, useState } from "react";

import useDetermineDropdownPosition from "./hooks/useDetermineDropdownPosition";

export const DROP_POSITIONS = {
  AUTO: "auto",
  BOTTOM: "bottom",
} as const;

export type DropPositionType =
  (typeof DROP_POSITIONS)[keyof typeof DROP_POSITIONS];

type DateInputPropType = {
  wrapperClassName?: string;
  label?: string;
  keyId: string;
  uppercaseLabel?: boolean;
  errorMessage?: string;
  value?: string;
  showFormat?: boolean;
  format?: string;
  placeholder?: string;
  disabled?: boolean;
  dropdownClassName?: string;
  dropPosition: DropPositionType;
  scrollContainerSelector?: string;
  maxDate?: Date;
  onChange: (value: CalendarValueType) => void;
  calendarClassName?: string;
  inputClassName?: string;
};

const DateInput = ({
  wrapperClassName = "",
  label = "",
  keyId,
  uppercaseLabel = false,
  errorMessage = "",
  value = "",
  showFormat = false,
  format = "DD-MM-YYYY",
  placeholder = "",
  disabled = false,
  dropdownClassName = "",
  dropPosition = DROP_POSITIONS.BOTTOM,
  scrollContainerSelector = "",
  maxDate = null,
  onChange,
  calendarClassName = "",
  inputClassName = "",
  ...props
}: React.ComponentPropsWithoutRef<"input"> & DateInputPropType) => {
  const ref = useRef(null);

  const [showCalendar, setShowCalendar] = useState(false);

  const classes: string = [
    "w-full h-4xl px-md rounded-lg font-manrope font-medium text-xs text-primary border border-solid transition-all duration-200 ease-in-out",
    "placeholder:text-secondary placeholder:opacity-100 placeholder:disabled:text-disabled disabled:placeholder:opacity-100",
    "focus:bg-primary focus:border-brand focus:outline-0",
    "disabled:text-disabled disabled:bg-accent disabled:border-transparent disabled:opacity-100",
    errorMessage
      ? "bg-primary border-danger focus:bg-primary focus:border-danger"
      : value
      ? "bg-primary border-primary"
      : "bg-accent border-transparent",
    inputClassName,
  ].join(" ");

  const dropPos = useDetermineDropdownPosition(
    ref,
    320,
    dropPosition,
    scrollContainerSelector,
  );

  const handleOpenDropdown = () => {
    if (disabled) return;
    setShowCalendar(true);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setShowCalendar(false);
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  return (
    <div className={`flex flex-col gap-sm ${wrapperClassName || ""}`}>
      {label && (
        <div className="w-fit">
          <InputLabel
            label={label}
            keyId={keyId}
            uppercaseLabel={uppercaseLabel}
          />
        </div>
      )}

      <div
        ref={ref}
        className="relative flex items-center"
        onFocus={handleOpenDropdown}
      >
        <input
          id={keyId}
          readOnly
          disabled={disabled}
          className={classes}
          value={showFormat ? value || "" : moment(value).format(format)}
          placeholder={
            showFormat && !placeholder?.length ? "yyyy/mm/dd" : placeholder
          }
          {...props}
        />
        <div
          className="absolute right-md flex items-center text-secondary cursor-pointer"
          onClick={handleOpenDropdown}
        >
          <CalendarIcon width={16} height={16} />
        </div>
        <div
          className={`absolute h-auto top-full left-0 z-[2] ${
            showCalendar ? "inline-block" : "hidden"
          } p-lg bg-primary rounded-lg shadow-alert ${dropPos} ${dropdownClassName}`}
        >
          <CalendarComponent
            calendarType="ISO 8601"
            showNeighboringMonth={false}
            className={calendarClassName}
            value={value ? new Date(value) : new Date()}
            maxDate={maxDate}
            onChange={(v) => {
              setShowCalendar(false);
              onChange(v);
            }}
          />
        </div>
      </div>

      {errorMessage && <ErrorMessage message={errorMessage} />}
    </div>
  );
};

export default DateInput;
