export const generateInitials = (name, lastName = "") => {
  if (!name?.trim() && !lastName?.trim()) return null;

  let firstName = name || "";
  if (!lastName) {
    [firstName, lastName = ""] = name?.split(" ");
  }

  return `${firstName.substring(0, 1).toUpperCase()}${lastName
    .substring(0, 1)
    .toUpperCase()}`;
};

export const DropdownAvatar = ({
  name,
  className,
  variant = "extra-small",
}) => {
  const classes = [
    `avatar ${className}`,
    variant ? `avatar--${variant}` : "",
  ].join(" ");

  return <span className={classes}>{generateInitials(name)}</span>;
};
