import CaretLeftIcon from "@shared/svg/caret-left.svg?react";
import CaretRighttIcon from "@shared/svg/caret-right.svg?react";
import Calendar from "react-calendar";

import CalendarStyles from "../../css/calendar.module.css";

export type CalendarValueType = string | Date | string[] | Date[];

type CalendarComponentPropsType = {
  calendarType?: string;
  showNeighboringMonth?: boolean;
  value: CalendarValueType;
  maxDate?: Date;
  className?: string;
  onChange: (value: CalendarValueType) => void;
  showDoubleView?: boolean;
  selectRange?: boolean;
  allowPartialRange?: boolean;
};

const CalendarComponent = ({
  calendarType = "ISO 8601",
  showNeighboringMonth = false,
  value,
  maxDate = null,
  className = "",
  onChange,
  showDoubleView = false,
  selectRange = false,
  allowPartialRange = false,
}: CalendarComponentPropsType) => {
  return (
    <Calendar
      calendarType={calendarType}
      showNeighboringMonth={showNeighboringMonth}
      className={`${
        showDoubleView
          ? CalendarStyles.dateRangeCalendar
          : CalendarStyles.dateInputCalendar
      } ${className}`}
      value={value}
      maxDate={maxDate}
      onChange={onChange}
      nextLabel={<CaretRighttIcon width={20} height={20} />}
      prevLabel={<CaretLeftIcon width={20} height={20} />}
      showDoubleView={showDoubleView}
      selectRange={selectRange}
      allowPartialRange={allowPartialRange}
    />
  );
};

export default CalendarComponent;
