import ChevronDownIcon from "@shared/svg/chevron-down.svg?react";
import { ErrorMessage, InputLabel } from "@shared/ui/Inputs";
import React from "react";
import Select from "react-dropdown-select";

import SingleSelectAsyncItem, {
  WaypointEventType,
} from "./SingleSelectAsyncItem";
import SingleSelectItem from "./SingleSelectItem";
import DropdownStyles from "../../../css/dropdowns.module.css";

export const DROPDOWN_POSITIONS = {
  BOTTOM: "bottom",
  TOP: "top",
  AUTO: "auto",
} as const;

export type DropdownPositionType =
  (typeof DROPDOWN_POSITIONS)[keyof typeof DROPDOWN_POSITIONS];

type SingleSelectDropdownPropsType = {
  keyId: string;
  label?: string;
  uppercaseLabel?: boolean;
  errorMessage?: string;
  placeholder?: string;
  options?: any[];
  values?: any[];
  disabled?: boolean;
  dropdownPosition?: DropdownPositionType;
  closeOnSelect?: boolean;
  searchable?: boolean;
  dropdownHeight?: string;
  onChange?: (value: any[]) => void;
  onDropdownOpen?: () => void;
  customInputClasses?: string;
  isAsync?: boolean;
  asyncProps?: {
    isLoading?: boolean;
    onScrollToBottom?: (event: WaypointEventType) => void;
    onSearch?: (term: string) => void;
  };
  showAvatar?: boolean;
};

const SingleSelectDropdown = ({
  keyId,
  label = "",
  uppercaseLabel = false,
  errorMessage = "",
  placeholder = "",
  options = [],
  values = [],
  disabled = false,
  dropdownPosition = DROPDOWN_POSITIONS.BOTTOM,
  closeOnSelect = true,
  searchable = false,
  dropdownHeight = "200px",
  onChange = () => {},
  onDropdownOpen = () => {},
  customInputClasses = "",
  isAsync = false,
  asyncProps = null,
  showAvatar = false,
  ...props
}: SingleSelectDropdownPropsType) => {
  const classes: string = `!w-full h-4xl !flex !items-center !px-md !py-0 font-manrope text-xs font-medium !border !border-solid !rounded-lg outline-0 transition-all duration-200 ease-in-out focus-within:!outline-0 focus-within:!border focus-within:!border-solid focus-within:!shadow-none hover:!outline-0 hover:!shadow-none aria-expanded:z-[99999] ${
    errorMessage
      ? "bg-primary !border-danger focus-within:!border-danger aria-expanded:!border-danger"
      : disabled
      ? "text-tertiary bg-accent !border-transparent !opacity-100"
      : (values.length
          ? "text-primary bg-primary !border-primary"
          : "text-secondary bg-accent !border-transparent") +
        " focus-within:!bg-primary focus-within:!border-brand aria-expanded:!bg-primary aria-expanded:!border-brand"
  }`;

  const renderCustomDropdown = ({ props, state, methods }) => {
    if (isAsync) {
      return (
        <SingleSelectAsyncItem
          props={props}
          state={state}
          methods={methods}
          isLoading={asyncProps?.isLoading}
          onScrollToBottom={asyncProps?.onScrollToBottom}
          onSearch={asyncProps?.onSearch}
          showAvatar={showAvatar}
        />
      );
    }
    return (
      <SingleSelectItem
        props={props}
        state={state}
        methods={methods}
        showAvatar={showAvatar}
      />
    );
  };

  return (
    <div className="relative flex flex-col gap-sm">
      {label && (
        <div className="w-fit">
          <InputLabel
            label={label}
            keyId={keyId}
            uppercaseLabel={uppercaseLabel}
          />
        </div>
      )}

      <Select
        dropdownHandleRenderer={() => (
          <span className="flex items-center justify-center">
            <ChevronDownIcon
              width={16}
              height={16}
              className={disabled ? "text-disabled" : "text-secondary"}
            />
          </span>
        )}
        searchable={searchable}
        className={`${customInputClasses || classes} ${
          DropdownStyles.dropdowns
        } ${values.length ? "has-value" : ""}`}
        placeholder={placeholder}
        options={options}
        dropdownHeight={dropdownHeight}
        closeOnSelect={closeOnSelect}
        values={values}
        onChange={onChange}
        dropdownRenderer={renderCustomDropdown}
        disabled={disabled}
        onDropdownOpen={onDropdownOpen}
        dropdownPosition={dropdownPosition}
        {...props}
      />

      {errorMessage && <ErrorMessage message={errorMessage} />}
    </div>
  );
};

export default SingleSelectDropdown;
